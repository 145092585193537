import React from 'react'
import {Link,Outlet} from 'react-router-dom'


export const MyNav = () => {
  return (<>
  <div>
  <div style={{
      display:'flex',
      backgroundColor:'white',
      boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
  }}>
      <div style={{
          textAlign:'start',
          width:'30%'
      }}>
          <h1 style={{
              color:'#0BC89F',
              margin:'13px 0 13px 15px',
              fontSize:18,
              fontWeight:700
          }}>MOUNTREACH</h1>

      </div>
     

  
    <div style={{
        width:'70%',
        margin:"0 10% 0px 10%",
        display:'flex',
        flexDirection:'row',
        flexFlow:'wrap',
        justifyContent:"space-around",
        alignItems:'center',
        fontSize:12
    }}>
        <Link to='/'>Home</Link>
        <Link to='/preq'>Purchase Req</Link>
        <Link to='/Accept'>Sell Req</Link>
        <Link to='/bill'>Sells</Link>
        <Link to='/Purchese'>Purchases</Link>
        <Link to='/req'>Emails</Link>
        <Link to='/Ledger'>View Ledger</Link>
        <Link to='/voucher'>Voucher</Link>
        {/* <Link to='/Vendor_info'>Vendor Info</Link> */}
        <Link to='/Vendor_list'>Vendor List</Link>
    
        <Outlet/>
    </div>
    </div>
    </div>
    </>
  )
}
