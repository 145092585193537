import React, { useEffect, useState } from 'react'
import { MDBBtn, MDBIcon, MDBInput } from 'mdb-react-ui-kit';
import {  getAuth, onAuthStateChanged } from "firebase/auth";
import { getDatabase, ref, update,push,child,get} from "firebase/database";
import {app} from "./api.js";
import html2pdf from 'html2pdf.js';
import {useNavigate} from 'react-router-dom'
import CashNumbering from './CashNumbering.js';
import axios from 'axios';
import logo from './mylogo.png'
import moment from 'moment';
import { MyNav } from './MyNav.js';




const auth = getAuth(app);
const db = getDatabase(app);
const dbRef = ref(getDatabase(app));

const Purchesereq = () => {
  
    const [AUser, setAUser] = useState([]);
  const [pr, setpr] = useState(false);
  const [bill, setbill] = useState()

  const [showme, setshowme] = useState(false)
  const [payment, setpayment] = useState(false)
  const [Mode, setMode] = useState("Cash");
  const [ChequeNo, setChequeNo] = useState("");
  const [debitto, setdebitto] = useState(""); 
  const [Bankname, setBankname] = useState("");
  const [BankRefNo, setBankRefNo] = useState("");
  const [DateOfPayment, setDateOfPayment] = useState("");
  const [branch, setbranch] = useState('001');
  const [loading, setloading] = useState(false);
  const [Amount, setAmount] = useState('')
  const [Remark, setRemark] = useState('')
  const [alertcolor, setalertcolor] = useState('green');
  const [alert, setalert] = useState(false);
  const [alertmsg, setalertmsg] = useState(null);
  
  const [DebitAssetstype, setDebitAssetstype] = useState("Assets-Cash");
  const myalart =(color,msg,btn)=>{
    setalertcolor(color);
    setalertmsg(msg);
    setalert(btn);
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      alert && setalert(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, [alert]);

  // const {id} = useParams();
  const [ldata, setldata] = useState([]);

  const getData = async()=>{
    const api = 'https://api.mountreachpos.duniyape.in'+'/get-ledger/I89nD59Ra69j0ee512TsW143eEt03u'
    try{
      const res = await axios.get(api);
      if(res){
        setldata(Object.values(res.data));
        console.log(Object.values(res.data));
      }
      
    }catch(error){
      console.log(error);
    }
  }

  useEffect(() => {
      getData();
    }, [])



  const navi = useNavigate();
  useEffect(() => {
    onAuthStateChanged(auth, user =>{
      if(user){

        const em =  user.email
        get(child(dbRef, `adminemail/`)).then((snapshot) => {
            if (snapshot.exists()) {
              const radharani = snapshot.val()
              setshowme(true);
              if (radharani.email===em) {
              }else{
                // navi('/')
  
                }
            } else {
              // navi('/')
            }
          }).catch((error) => {
            // navi('/')
          });

        // get(child(dbRef, `auth/`+user.uid)).then((snapshot) => {
        //   if (snapshot.exists()) {
        //     const check = snapshot.val()
        //     if (check.status==="false") {
        //       navi('/wait')
          
        //     } else {
        //       setshowme(true);
        //     }
        //   } else {
        //     console.log("No data available");
        //     navi('/')
        //   }
        // }).catch((error) => {
        //   console.error(error);
        //   navi('/')
        // });


      }else{
        navi('/')
      }
    });
  }, [])

    useEffect(() => {

        onAuthStateChanged(auth, user =>{
          if(user){

            get(child(dbRef, `buy/`)).then((snapshot) => {
                if (snapshot.exists()) {
    
                    const fdata = Object.values(snapshot.val())
                    const pending = fdata.filter((item)=> item.Status==='pending')
                    // const mpending = pending.filter((item)=> item.Email===user.email)
                    setAUser(pending)
                  console.log(pending);
                  
                } else {
                  console.log("No data available");
                }
              }).catch((error) => {
                console.error(error);
              });

          }else{
    
          }
        });
  
      }, [])

      const convertToPdf = () => {
        const element = document.getElementById('element-to-convert'); // Replace 'element-to-convert' with the ID of the HTML element you want to convert
    
        if (!element) {
          console.error('Element not found');
          return;
        }
    
        html2pdf()
          .from(element)
          .save();
      };

      const Accept=()=>{
        setpayment(true)


        
    
      
        
      }
    




      const convertToWords = (num) => {
        const units = ['', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine'];
        const teens = ['Ten', 'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen'];
        const tens = ['', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];
    
        if (num === 0) return 'Zero';
    
        let words = '';
    
        if (num >= 10000000 && num <= 999999999) {
          words += convertToWords(Math.floor(num / 10000000)) + ' Crore ';
          num %= 10000000;
        }
    
        if (num >= 100000 && num <= 9999999) {
          words += convertToWords(Math.floor(num / 100000)) + ' Lakh ';
          num %= 100000;
        }
    
        if (num >= 1000 && num <= 99999) {
          words += convertToWords(Math.floor(num / 1000)) + ' Thousand ';
          num %= 1000;
        }
    
        if (num >= 100 && num <= 999) {
          words += units[Math.floor(num / 100)] + ' Hundred ';
          num %= 100;
        }
    
        if (num >= 20 && num <= 99) {
          words += tens[Math.floor(num / 10)] + ' ';
          num %= 10;
        }
    
        if (num >= 10 && num <= 19) {
          words += teens[num - 10];
          num = 0;
        }
    
        if (num >= 1 && num <= 9) {
          words += units[num];
        }
    
        return words.trim();
      };


      useEffect(() => {
        if (Mode==="Cheque") {
          setDebitAssetstype("Assets-Bank");
          setdebitto("");
  
        } else if(Mode==="Bank Transfer"){
          setDebitAssetstype("Assets-Bank");
          setdebitto("");
        } else if(Mode==="Cash"){
          setDebitAssetstype("Assets-Cash");
          setdebitto("A11");
        } else{
          setdebitto("");
        }
      }, [Mode])

      const checkMOP=()=>{
        let Tatalamount=bill.product[0].paid

        if (parseFloat(Tatalamount)===0) {
          setAmount(0)
        }
        
        if (Mode==="Cheque") {
          if(debitto===""||ChequeNo===""||Bankname===""||DateOfPayment===""){
          myalart('#DD4444','Please fill Cheque Details',true);
          }else{ 
               if (parseFloat(Tatalamount)!==parseFloat(Amount)) {
                myalart('#DD4444','Deposit Amount is not Equal to Voucher Amount ',true);
              }else{
                create()
              }
          }
        } else if(Mode==="Bank Transfer") {
          if(debitto===""||BankRefNo===""||DateOfPayment===""){
            myalart('#DD4444','Please fill Bank Transfer Details',true);
          }else{ 
               if (parseFloat(Tatalamount)!==parseFloat(Amount)) {
                myalart('#DD4444','Deposit Amount is not Equal to Voucher Amount ',true);
              }else{
                create()
              }
          }
        }else if(Mode==="Cash"){
             if (parseFloat(Tatalamount)!==parseFloat(Amount)) {
              myalart('#DD4444','Deposit Amount is not Equal to Voucher Amount ',true);
            }else{
              create()
            }

        }
      }

      

      const [id2, setid2] = useState(null);
      useEffect(() => {
        if (id2===null) {
          
        } else {
          setAmount(id2.amount);
        }
      }, [id2])
      const [vid, setvid] = useState('')
      const getvdata=async()=>{
        try {
            await axios.get('https://api.mountreachpos.duniyape.in/create-vendor/I89nD59Ra69j0ee512TsW143eEt03u')
            .then((res)=>{
                let data=Object.values(res.data)
                console.log(data)
                // setvdata(data)
                let f=data.filter(item=>item.PAN===bill.product[0].PAN)
               if (f.length>0)
               {
                console.log(f[0].Code)
                setvid(f[0].Code)
               }
               else{
                myalart('#DD4444','Vendor Not Found',true)
              }
            })
    
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
      getvdata()
    }, [bill])
      const create=()=>{
        setloading(true);
        axios.post('https://api.mountreachpos.duniyape.in/'+'accept-purchase-voucher'+'/'+process.env.REACT_APP_API_KEY,{
            Balance : bill.Total,
            AccountNumber:vid,
            BranchCode:"001",
            date: bill.Date,
            AccountType: '',
            ledgerName: 'A64',
            PaymentMode: Mode,
            DebitTo : debitto,
            ChequeNo : ChequeNo,
            BankName : Bankname,
            DateOfPayment : DateOfPayment,
            BankRef: BankRefNo,
            Remarks: Remark,EmpID:'',
            Advance:bill.product[0].paid,
            PurchaseLedger:"A69",
            Nareshan:'Purchase'
        })
        .then(function (response) {
          console.log(response.data)
          if(response.data.error){
            myalart('#DD4444','Deposit Failed',true);
            setloading(false);
          }else{
            myalart('green',`Deposit Successfully ${response.data.VsKey?'Txn -'+response.data.VsKey:" "}`,true);
            // setAmount("");
            setloading(false);

            get(child(dbRef, `buy/`)).then((snapshot) => {
              if (snapshot.exists()) {
                const num = Object.values(snapshot.val());
                const pending = num.filter((item)=> item.Status==='Accepted')
        
                const c = pending.length+201;
                update(ref(db, 'buy/'+ bill.Pushkey+'/'), {Status:'Accepted',BillNumber: c}).then(()=>navi('/'));
          
              } else {
                const c = 201
                update(ref(db, 'buy/'+ bill.Pushkey+'/'), {Status:'Accepted',BillNumber: c}).then(()=>navi('/'));
              
              }
            }).catch((error) => {
              console.error(error);
            });
          }
          
        })
        .catch(function (error) {
            console.log(error);
            myalart('#DD4444','Failed',true);
            setloading(false);
            
    
        }); 
     
      }



      if (pr) {
        return (
            <>
            {bill&&<div>
            <div style={{backgroundColor:"black",display:"flex",flexDirection:"column",alignItems:"center",fontSize:"12px"}}>
                <div id="element-to-convert" style={{width:800,backgroundColor:"white",padding:"20px"}}>
          <div style={{position:"relative"}}>
           <div><img src={logo} alt='WaterMark' style={{width:"300px",position:'absolute',top: "30%", left: "30%",opacity:0.15}}></img></div>
                <div style={{border:"2px solid black",padding:"10px"}}>
                    <div style={{display:"flex",justifyContent:"space-between",fontWeight:"bold",fontSize:"20px"}}>
                        <div>SELLER</div>
                        <div>Purchased Order Acknowledgement</div>
                    </div>
                    <div style={{display:"flex",justifyContent:"space-between"}}>
                        <div>नाम -{bill.Name}</div>
                        {/* <div><img src={logo} alt='logo' style={{width:"150px"}}></img></div> */}
                        <div>ब्रोकर -{bill.Agent}</div>
                    </div>
                    <div style={{display:"flex",justifyContent:"space-between"}}>
                        <div>PAN-{bill.product[0].PAN}</div>
                        <div>GSTIN NO. -  23AALCM7165N1ZW</div>
                    </div>
                    <div style={{display:"flex",justifyContent:"space-between"}}>
                        <div>पता -{bill.product[0].Address}</div>
                        <div>मो. नं  - 8319633066</div>
                        
                    </div>
                    <div style={{display:"flex",justifyContent:"space-between"}}>
                        <div>मो. नं -{bill.product[0].Mobile}</div>
                        <div>दिनांक -{moment(bill.Date).format('yyyy-MM-DD')}</div>
                    </div>
        
                    <div>
                        <table style={{width:"100%",border:"2px solid black",borderCollapse: "collapse",margin:"10px 0",textAlign:"center"}}>
                            <tr>
                                <th style={{border:"1px solid black",padding:"8px"}}>क्र.</th>
                                <th style={{border:"1px solid black"}}>माल का नाम</th>
                                <th style={{border:"1px solid black"}}>बोरा/बोरी</th>
                                <th style={{border:"1px solid black"}}>भरती</th>
                                <th style={{border:"1px solid black"}}>वजन ( {bill.product[0].weighttype==="qt"?"क्विंटल":bill.product[0].weighttype==="kg"?"किलो":"टन"} )</th>
                                <th style={{border:"1px solid black",width:"9%"}}>वजन कटोत्रा ( बोरा/बोरी )</th>
                                <th style={{border:"1px solid black"}}>कुल वजन</th>
                                <th style={{border:"1px solid black"}}>भाव</th>
                                <th style={{border:"1px solid black"}}>गुणवत्ता कटोत्रा</th>
                                <th style={{border:"1px solid black"}}>माल की कीमत</th>
                            </tr>
                           
                            {bill.product.map((item, index) => (
                                <tr>
                                  <td style={{border:"1px solid black",padding:"8px"}}>{index+1}</td>
                                <td style={{border:"1px solid black"}}>{item.product}</td>
                                <td style={{border:"1px solid black"}}>{item.bags}</td>
                                <td style={{border:"1px solid black"}}>{item.average}</td>
                                <td style={{border:"1px solid black"}}>{item.weight}</td>
                                <td style={{border:"1px solid black"}}>{item.deduction}</td>
                                <td style={{border:"1px solid black"}}>{item.totalweight}</td>
                                <td style={{border:"1px solid black"}}>{item.rate}</td>
                                <td style={{border:"1px solid black"}}>{item.qualityclaim}</td>
                                <td style={{border:"1px solid black"}}>{item.Totalamount}/-</td>
                                
                                </tr>
                            ))} 
                
                                <tr>
                                  <td style={{border:"1px solid black",padding:"8px"}}></td>
                                <td style={{border:"1px solid black"}}></td>
                                <td style={{border:"1px solid black"}}></td>
                                <td style={{border:"1px solid black"}}></td>
                                <td style={{border:"1px solid black"}}></td>
                                <td style={{border:"1px solid black"}}></td>
                                <td style={{border:"1px solid black"}}></td>
                                <td style={{border:"1px solid black"}}></td>
                                <td style={{border:"1px solid black",fontWeight:"bold"}}>कुल राशि -</td>
                                <td style={{border:"1px solid black",fontWeight:"bold"}}>{bill.Total}/-</td>
                                
                                </tr>
                                
                               
                            
                        </table>
                    </div>
                    <div>कुल राशि शब्दों में - {convertToWords(bill.Total)} Only </div>
        
                     <hr style={{border:" 1px solid black"}}/>
                     <div style={{display:"flex",justifyContent:"space-between"}}>
                        <div>अग्रिम भुगतान -{bill.product[0].paid} </div>
                        <div>शेष राशि-{bill.Total-bill.product[0].paid}</div>
                        <div></div>
                    </div>
        
                     <hr style={{border:" 1px solid black"}}/>
                    <div style={{display:"flex",justifyContent:"center",marginTop:"50px"}}>
                        <div>Note* - This is an Computer Generated  doesn't require signature.</div>
                    </div>
                      
                    
                    </div>
                </div>
        </div>
        <div style={{display:"flex",justifyContent:"center",alignItems:"center",margin:"20px"}}>

          <MDBBtn rounded className='mx-2' color='primary' onClick={convertToPdf}>Convert to PDF</MDBBtn>
          <MDBBtn rounded className='mx-2' color='primary' onClick={Accept}>Approve</MDBBtn>
          <MDBBtn rounded className='mx-2' color='primary' onClick={()=>setpr(false)}>Back To Home</MDBBtn>
        
        
                  
        </div>
            {/* <div style={{width:'100%',textAlign:'center'}}>
            <img src={bill.Url} alt='img'/>
            </div> */}
            </div>

        {payment&&<div style={{
  margin:"20px 2% 0px 2%",
  display:'flex',
  flexDirection:'row',
  flexFlow:'wrap',
  // justifyContent:"space-around"
}}>
<div style={{width:"100%",padding:"5px 5px 5px 20px",margin:"30px 0px 10px 0px",backgroundColor:"#636694",fontSize:10,color:"white"}}>Payment</div>



<div style={{padding:5, textAlign:"end",width:300}}>
<label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>Payment mode</label>
<select value={Mode} onChange={(e)=>setMode(e.target.value)} style={{fontSize:12,padding:"3px 10px 5px 10px",width:169}}>
    <option value="">-Select-</option>     
    <option value="Cash">Cash</option>     
    <option value="Cheque">Cheque</option>     
    <option value="Bank Transfer">Bank Transfer</option>        
</select>
</div>

<div style={{padding:5, textAlign:"end",width:300}}>
<label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>Credit To</label>
<select value={debitto} onChange={(e)=>setdebitto(e.target.value)} style={{fontSize:12,padding:"3px 10px 5px 10px",width:169}}>
    <option value="">-Select-</option>
    {
ldata.filter((item)=> item.GroupName===DebitAssetstype).map((item , index)=>(
  <option key={index} value={item.Pushkey}>{item.LedgerName}</option>
))
}
</select>
</div>
<div style={{padding:5, textAlign:"end",width:300}}>
<label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>Amount</label>
<input value={bill.product[0].paid}  placeholder='Enter Plan Name'style={{fontSize:12,padding:"3px 10px 5px 10px",width:169}} type='text'/>
</div>

{
Mode==="Cash"?<>
<CashNumbering x = {id2} setX={setid2} text={"Deposit"}/>
</>:<></>
}

{
Mode==="Cheque"?<>
<div style={{padding:5, textAlign:"end",width:300}}>
<label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>cheque No.</label>
<input value={ChequeNo} onChange={(e)=>setChequeNo(e.target.value)} placeholder='Enter Plan Name'style={{fontSize:12,padding:"3px 10px 5px 10px",width:169}} type='text'/>
</div>

<div style={{padding:5, textAlign:"end",width:300}}>
<label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>Bank Name</label>
<input value={Bankname} onChange={(e)=>setBankname(e.target.value)} placeholder='Enter Plan Name'style={{fontSize:12,padding:"3px 10px 5px 10px",width:169}} type='text'/>
</div>

<div style={{padding:5, textAlign:"end",width:300}}>
<label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>Date</label>
<input value={DateOfPayment} onChange={(e)=>setDateOfPayment(e.target.value)} placeholder='Enter Plan Name' style={{fontSize:12,padding:"3px 10px 5px 10px",width:169}} type='date'/>
</div>

<div style={{width:"100%",padding:"5px 5px 5px 20px",margin:"10px 0px 10px 0px",backgroundColor:"#636694",fontSize:10,color:"white"}}>Update Plan</div>

<div style={{padding:5, textAlign:"end",width:300}}>
<label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>Deposit Amount</label>
<input value={Amount} onChange={(e)=>setAmount(e.target.value)} placeholder='Enter Plan Name'style={{fontSize:12,padding:"3px 10px 5px 10px",width:169}} type='number'/>
</div>
</>:<></>
}


{
Mode==="Bank Transfer"?<>
<div style={{padding:5, textAlign:"end",width:300}}>
<label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>Bank Ref. No.</label>
<input value={BankRefNo} onChange={(e)=>setBankRefNo(e.target.value)} placeholder='Enter Plan Name'style={{fontSize:12,padding:"3px 10px 5px 10px",width:169}} type='text'/>
</div>

<div style={{padding:5, textAlign:"end",width:300}}>
<label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>Date</label>
<input  value={DateOfPayment} onChange={(e)=>setDateOfPayment(e.target.value)} placeholder='Enter Plan Name' style={{fontSize:12,padding:"3px 10px 5px 10px",width:169}} type='date'/>
</div>


<div style={{width:"100%",padding:"5px 5px 5px 20px",margin:"10px 0px 10px 0px",backgroundColor:"#636694",fontSize:10,color:"white"}}>Update Plan</div>

<div style={{padding:5, textAlign:"end",width:300}}>
<label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>Deposit Amount</label>
<input value={Amount} onChange={(e)=>setAmount(e.target.value)} placeholder={'Enter Amount'}style={{fontSize:12,padding:"3px 10px 5px 10px",width:169}} type='number'/>
</div>
</>:<></>
}


<div style={{padding:5, textAlign:"end",width:300}}>
<label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>Remarks</label>
<input value={Remark} onChange={(e)=>setRemark(e.target.value)} placeholder={'Enter Remarks'}style={{fontSize:12,padding:"3px 10px 5px 10px",width:169}} type='text'/>
</div>

{
loading? <div style={{width:"100%", height:100, textAlign:"end"}}>
<span class="loader"></span>
</div>
:
<div style={{width:"100%", height:100, textAlign:"end"}}>
<button onClick={checkMOP}  style={{borderRadius:2,padding:"5px 30px 5px 30px",margin:"20px 10px 0px 0px", fontSize:12,backgroundColor:"#322869"}} type="button" className="btn btn-success">Create</button>
</div>
}

    
    </div>}
      </div>
        
        
        
        
        }</>
          )
    } else {
    
    if (showme) {
      return (
        <div>
            <MyNav/>
            {AUser.map((item, index) => (
    <div style={{display:"flex",justifyContent:"space-evenly",alignItems:"center", boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',margin:"3%",padding:"5px"}}>
     <div style={{padding:"5px"}}>
          <div>{item.Name}</div>  
          <div>{item.Email}</div>  
          <div>{item.Date}</div>  
     </div>
     <div>
     <MDBBtn style={{marginRight:10}} rounded color='info gradient' onClick={()=>{setpr(true);setbill(item)}}>View</MDBBtn>
     <MDBBtn rounded color='info gradient' onClick={()=>navi(`/purchase_edit/${item.Pushkey}`)}>Edit</MDBBtn>
    </div>
    </div>
    ))}
        </div>
      )
    } else {
      return(
        <div>
          loading...
        </div>
      )
    }
    
        
    }

}

export default Purchesereq
