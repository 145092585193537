import React, { useEffect, useState } from 'react'
import { MDBBtn, MDBIcon, MDBInput } from 'mdb-react-ui-kit';
import {  getAuth, onAuthStateChanged } from "firebase/auth";
import { getDatabase, ref, update,push,child,get} from "firebase/database";
import {app} from "./api.js";
import html2pdf from 'html2pdf.js';
import {useNavigate,useParams} from 'react-router-dom'

import logo from './mylogo.png'
import moment from 'moment';




const dbRef = ref(getDatabase(app));
const auth = getAuth(app);

const Purchesebill = () => {


    const [AUser, setAUser] = useState([]);
  const [pr, setpr] = useState(false);
  const [bill, setbill] = useState()

  const [showme, setshowme] = useState(false)

  const {id} = useParams();


  const navi = useNavigate();
  useEffect(() => {
    onAuthStateChanged(auth, user =>{
      if(user){


        get(child(dbRef, `auth/`+user.uid)).then((snapshot) => {
          if (snapshot.exists()) {
            const check = snapshot.val()
            if (check.status==="false") {
              navi('/wait')
          
            } else {
              setshowme(true);
            }
          } else {
            console.log("No data available");
            navi('/')
          }
        }).catch((error) => {
          console.error(error);
          navi('/')
        });


      }else{
        navi('/')
      }
    });
  }, [])

    useEffect(() => {

        onAuthStateChanged(auth, user =>{
          if(user){

            get(child(dbRef, `buy/`)).then((snapshot) => {
                if (snapshot.exists()) {
    
                    const fdata = Object.values(snapshot.val())
                    const pending = fdata.filter((item)=> item.Status==='Accepted')
                    const mpending = pending.filter((item)=> item.Email===user.email)
                    setAUser(mpending)
                  console.log(mpending);
                  
                } else {
                  console.log("No data available");
                }
              }).catch((error) => {
                console.error(error);
              });

          }else{
    
          }
        });
  
      }, [])

      const convertToPdf = () => {
        const element = document.getElementById('element-to-convert'); // Replace 'element-to-convert' with the ID of the HTML element you want to convert
    
        if (!element) {
          console.error('Element not found');
          return;
        }
    
        html2pdf()
          .from(element)
          .save();
      };




      const convertToWords = (num) => {
        const units = ['', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine'];
        const teens = ['Ten', 'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen'];
        const tens = ['', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];
    
        if (num === 0) return 'Zero';
    
        let words = '';
    
        if (num >= 10000000 && num <= 999999999) {
          words += convertToWords(Math.floor(num / 10000000)) + ' Crore ';
          num %= 10000000;
        }
    
        if (num >= 100000 && num <= 9999999) {
          words += convertToWords(Math.floor(num / 100000)) + ' Lakh ';
          num %= 100000;
        }
    
        if (num >= 1000 && num <= 99999) {
          words += convertToWords(Math.floor(num / 1000)) + ' Thousand ';
          num %= 1000;
        }
    
        if (num >= 100 && num <= 999) {
          words += units[Math.floor(num / 100)] + ' Hundred ';
          num %= 100;
        }
    
        if (num >= 20 && num <= 99) {
          words += tens[Math.floor(num / 10)] + ' ';
          num %= 10;
        }
    
        if (num >= 10 && num <= 19) {
          words += teens[num - 10];
          num = 0;
        }
    
        if (num >= 1 && num <= 9) {
          words += units[num];
        }
    
        return words.trim();
      };




      if (pr) {
        return (
            <>
            {bill&&<div>
            <div style={{backgroundColor:"black",display:"flex",flexDirection:"column",alignItems:"center",fontSize:"12px"}}>
                <div id="element-to-convert" style={{width:800,backgroundColor:"white",padding:"20px"}}>
          <div style={{position:"relative"}}>
           <div><img src={logo} alt='WaterMark' style={{width:"300px",position:'absolute',top: "30%", left: "30%",opacity:0.15}}></img></div>
                <div style={{border:"2px solid black",padding:"10px"}}>
                    <div style={{display:"flex",justifyContent:"space-between",fontWeight:"bold",fontSize:"20px"}}>
                        <div>SELLER</div>
                        <div>Purchased Order Acknowledgement</div>
                    </div>
                    <div style={{display:"flex",justifyContent:"space-between"}}>
                        <div>नाम -{bill.Name}</div>
                        {/* <div><img src={logo} alt='logo' style={{width:"150px"}}></img></div> */}
                        <div>ब्रोकर -{bill.Agent}</div>
                    </div>
                    <div style={{display:"flex",justifyContent:"space-between"}}>
                        <div>PAN-{bill.product[0].PAN}</div>
                        <div>GSTIN NO. -  23AALCM7165N1ZW</div>
                    </div>
                    <div style={{display:"flex",justifyContent:"space-between"}}>
                        <div>पता -{bill.product[0].Address}</div>
                        <div>मो. नं  - 8319633066</div>
                        
                    </div>
                    <div style={{display:"flex",justifyContent:"space-between"}}>
                        <div>मो. नं -{bill.product[0].Mobile}</div>
                        <div>दिनांक -{moment(bill.Date).format('yyyy-MM-DD')}</div>
                    </div>
        
                    <div>
                        <table style={{width:"100%",border:"2px solid black",borderCollapse: "collapse",margin:"10px 0",textAlign:"center"}}>
                            <tr>
                            <th style={{border:"1px solid black",padding:"8px"}}>क्र.</th>
                        <th style={{border:"1px solid black"}}>माल का नाम</th>
                        <th style={{border:"1px solid black"}}>बोरा/बोरी</th>
                        <th style={{border:"1px solid black"}}>भरती</th>
                        <th style={{border:"1px solid black"}}>वजन ( {bill.product[0].weighttype==="qt"?"क्विंटल":bill.product[0].weighttype==="kg"?"किलो":"टन"} )</th>
                        <th style={{border:"1px solid black",width:"9%"}}>वजन कटोत्रा ( बोरा/बोरी )</th>
                        <th style={{border:"1px solid black"}}>कुल वजन</th>
                        <th style={{border:"1px solid black"}}>भाव</th>
                        <th style={{border:"1px solid black"}}>गुणवत्ता कटोत्रा</th>
                        <th style={{border:"1px solid black"}}>माल की कीमत</th>
                            </tr>
                           
                            {bill.product.map((item, index) => (
                                <tr>
                                  <td style={{border:"1px solid black",padding:"8px"}}>{index+1}</td>
                                  <td style={{border:"1px solid black"}}>{item.product}</td>
                                <td style={{border:"1px solid black"}}>{item.bags}</td>
                                <td style={{border:"1px solid black"}}>{item.average}</td>
                                <td style={{border:"1px solid black"}}>{item.weight}</td>
                                <td style={{border:"1px solid black"}}>{item.deduction}</td>
                                <td style={{border:"1px solid black"}}>{item.totalweight}</td>
                                <td style={{border:"1px solid black"}}>{item.rate}</td>
                                <td style={{border:"1px solid black"}}>{item.qualityclaim}</td>
                                <td style={{border:"1px solid black"}}>{item.Totalamount}/-</td>
                                
                                </tr>
                            ))} 
                
                                <tr>
                                  <td style={{border:"1px solid black",padding:"8px"}}></td>
                                <td style={{border:"1px solid black"}}></td>
                                <td style={{border:"1px solid black"}}></td>
                                <td style={{border:"1px solid black"}}></td>
                                <td style={{border:"1px solid black"}}></td>
                                <td style={{border:"1px solid black",fontWeight:"bold"}}>कुल राशि -</td>
                                <td style={{border:"1px solid black",fontWeight:"bold"}}>{bill.Total}/-</td>
                                
                                </tr>
                                
                               
                            
                        </table>
                    </div>
                    <div>कुल राशि शब्दों में - {convertToWords(bill.Total)} Only </div>
        
                     <hr style={{border:" 1px solid black"}}/>
                     <div style={{display:"flex",justifyContent:"space-between"}}>
                        <div>अग्रिम भुगतान -{bill.product[0].paid} </div>
                        <div>शेष राशि-{bill.Total-bill.product[0].paid}</div>
                        <div></div>
                    </div>
        
                     <hr style={{border:" 1px solid black"}}/>
                    <div style={{display:"flex",justifyContent:"center",marginTop:"50px"}}>
                        <div>Note* - This is an Computer Generated  doesn't require signature.</div>
                    </div>
                      
                    
                    </div>
                </div>
        </div>
        <div style={{display:"flex",justifyContent:"center",alignItems:"center",margin:"20px"}}>
          <MDBBtn rounded className='mx-2' color='primary' onClick={convertToPdf}>Convert to PDF</MDBBtn>
          <MDBBtn rounded className='mx-2' color='primary' onClick={()=>setpr(false)}>Back To Home</MDBBtn>
        
        
                  
        </div>
            {/* <div style={{width:'100%',textAlign:'center'}}>
            <img src={bill.Url} alt='img'/>
            </div> */}
            </div>
        
        </div>
        
        
        
        
        }</>
          )
    } else {
    
    if (showme) {
      return (
        <div>
            {AUser.map((item, index) => (
    <div style={{display:"flex",justifyContent:"space-evenly", boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',margin:"3%",padding:"5px"}}>
     <div style={{padding:"5px"}}>
          <div>{item.Name}</div>  
          <div>{item.Email}</div>  
          <div>{item.Date}</div>  
     </div>
     <div> 
     <MDBBtn rounded color='info gradient' onClick={()=>{setpr(true);setbill(item)}}>View</MDBBtn>
    </div>
    </div>
    ))}
        </div>
      )
    } else {
      return(
        <div>
          loading...
        </div>
      )
    }
    
        
    }
}

export default Purchesebill
