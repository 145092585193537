import React ,{useState,useEffect} from  'react'
import axios from "axios";
import { MyNav } from './MyNav';
import { useParams } from 'react-router-dom';

const VendorInfo = () => {
    const {id} = useParams();    
    const [loading, setloading] = useState(false);

    const [alertcolor, setalertcolor] = useState("");
    const [alert, setalert] = useState(false);
    const [alertmsg, setalertmsg] = useState("");
    const [Vdata, setVdata] = useState("");
    const [VID, setVID] = useState(id);


    const myalert =(color,msg,action)=>{
        setalertcolor(color);
        setalertmsg(msg);
        setalert(action);
    }
    
    
    const color =(index)=>{
        if ((index+1)% 2===0) {
          return "white"
        } else {
          return "#D5FFFC"
        }
      }

      useEffect(() => {
        const timer = setTimeout(() => {
          alert && setalert(false);
        }, 3000);
        return () => clearTimeout(timer);
      }, [alert]);

    const getdata=async()=>{
        try {
            await axios.get('https://api.mountreachpos.duniyape.in/create-vendor/'+"I89nD59Ra69j0ee512TsW143eEt03u")
            .then((res)=>{
                let data=Object.values(res.data)
                let f=data.filter(item=>item.Code===VID)
               if (f.length>0)
               {setVdata(f[0])
            getApiData(f[0])
            }
               else{myalert('#DD4444','Vendor Not Found',true)}
                console.log(f)
            })
    
        } catch (error) {
            console.log(error)
        }
    } 
    useEffect(() => {
     getdata()
    }, [VID])
    
    const [data, setdata] = useState([])
    const getApiData = async(obj)=>{
          const api = 'https://api.mountreachpos.duniyape.in'+'/get-personol-ledger/'+obj.Receiptdate+'/'+new Date('2025-05-05')+'/'+"I89nD59Ra69j0ee512TsW143eEt03u"
        try{
          const res = await axios.get(api);
          if(res){
            let data=Object.values(res.data).filter((item)=> item.AccountNumber===VID);
            setdata(data.sort((a,b)=>new Date(b)-new Date(a)))
            totalbalance(data)
            console.log(data);
          }
        }catch(error){
          console.log(error);
        }
      }

      // const [total, settotal] = useState('')
      // const totalbalance=(data)=>{
      //   let dr = 0;
      //   let cr = 0;
      //   for (let i = 0; i < data.length; i++) {
      //     dr = dr+parseFloat(data[i].Dr)
      //     cr = cr+parseFloat(data[i].Cr)
      //   }
      //   settotal({Dr:dr,Cr:cr})
      // }

      const totalbalance=(x)=>{
        console.log(data)
        let dr = 0;
        let cr = 0;
        for (let i = 0; i < x+1; i++) {
          dr = dr+parseFloat(data[i].Dr)
          cr = cr+parseFloat(data[i].Cr)
        }
        return dr-cr
      }

      

    return (
        <div style={{
            width:"100%"
        }}>

          <MyNav/>

{alert?<><div style={{
    width:"100%",
    backgroundColor:alertcolor,
    color:"white",
    textAlign:'center',
    padding:8,
  }}>
    <h6 style={{fontSize:12}}>{alertmsg}</h6>
  </div></>:<></>}

            <div style={{
            margin:"20px 2% 0px 2%",
            display:'flex',
            flexDirection:'row',
            flexFlow:'wrap',
            // justifyContent:"space-around"
        }}>
<div style={{width:"100%",padding:"8px 5px 8px 20px",textTransform:"Uppercase",margin:"10px 0px 10px 0px",backgroundColor:"#636694",fontSize:10,fontWeight:"bold", color:"white"}}>Vendor Info</div>


<div style={{padding:5, textAlign:"end",width:300}}>
    <label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>Vendor Id</label>
    <input value={VID} onChange={e=>setVID(e.target.value)} placeholder='Vendor ID'style={{fontSize:12,padding:"3px 10px 5px 10px",width:169}} type='text'/>
    </div>

    {/* <div style={{width:"100%",padding:"5px 5px 5px 20px",margin:"10px 0px 10px 0px",fontSize:12,textAlign:"end"}}>
        <button onClick={getdata} style={{padding:"3px 30px 3px 30px",color:"white",backgroundColor:"#322869"}}>Search</button>
    </div>  */}


<div style={{width:"100%",padding:"5px 5px 5px 20px",margin:"10px 0px 10px 0px",backgroundColor:"#636694",fontSize:10,color:"white"}}>Authorised Person</div>

<DivInput title='Vendor name' place='Vendor Name' value={Vdata.Fname}  type='text'/>

{/* <DivInput title='Last name' place='Enter Last Name' value={Vdata.Lname}  type='text'/>
<DivInput title='Designation' place='Enter Designation' value={Vdata.Designation} type='text'/> */}



{/* <div style={{width:"100%",padding:"5px 5px 5px 20px",margin:"10px 0px 10px 0px",backgroundColor:"#636694",fontSize:10,color:"white"}}>Firm details</div>



<div style={{padding:5, textAlign:"end",width:300}}>
<label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>Firm Name</label>
<input value={Vdata.FirmName}  placeholder='Firm Name' style={{fontSize:12,padding:"3px 10px 5px 10px"}} type='text'/>
</div>
<div style={{padding:5, textAlign:"end",width:300}}>
<label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>Firm Type</label>
<input value={Vdata.FirmType}  style={{fontSize:12,padding:"3px 10px 5px 10px",width:169}}>
     
</input>
</div>
<div style={{padding:5, textAlign:"end",width:300}}>
<label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>Firm Reg. No.</label>
<input value={Vdata.FirmReg} placeholder=Registration No style={{fontSize:12,padding:"3px 10px 5px 10px"}} type='number'/>
</div> */}



{/* <div style={{width:"100%",padding:"5px 5px 5px 20px",margin:"10px 0px 10px 0px",backgroundColor:"#636694",fontSize:10,color:"white"}}>Registered Address Details</div> */}

<div style={{padding:5, textAlign:"right",width:300, display:"flex",justifyContent:"right"}}>
<div>
{/* <span style={{fontSize:12,margin:"0px 15px 0px 15px"}}>Parmnent address</span> */}
<label style={{fontSize:12,margin:"0px 15px 0px 5px"}}>Registered Address</label>
</div>
<textarea value={Vdata.SPermanentAdd}  placeholder="Enter Village/Street/Ward" style={{fontSize:12,padding:"3px 10px 5px 10px",height:60,width:169}} type='text'/>
</div>

<DivInput title='Mobile' place='Mobile' value={Vdata.Other}  type='text'/>

{/* <DivInput title='City' place='Enter City Name' value={Vdata.SCity} type='text'/>

<div style={{padding:5, textAlign:"end",width:300}}>
<label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>State</label>
<input value={Vdata.Istate} style={{fontSize:12,padding:"3px 10px 5px 10px",width:169}}>

</input>
</div>


<div style={{padding:5, textAlign:"end",width:300}}>
<label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>District</label>
<input value={Vdata.District}  style={{fontSize:12,padding:"3px 10px 5px 10px",width:169}}>
</input>
</div>

<DivInput title='Pincode' place='Enter Pincode' value={Vdata.SPinCode} type='number'/>
 */}


{/* <div style={{width:"100%",padding:"5px 5px 5px 20px",margin:"10px 0px 10px 0px",backgroundColor:"#636694",fontSize:10,color:"white"}}>Billing Address Details</div>


<div style={{padding:5, textAlign:"right",width:300, display:"flex",justifyContent:"right"}}>
<div>
<label style={{fontSize:12,margin:"0px 15px 0px 8px"}}>Residence Address</label>
</div>
<textarea value={Vdata.SSResidanceAdd}  placeholder=Enter Village/Street/Ward style={{fontSize:12,padding:"3px 10px 5px 10px",height:60,width:169}} type='text'/>
</div>

<DivInput title='City' place='Enter City Name' value={Vdata.SSCity}  type='text'/>


<div style={{padding:5, textAlign:"end",width:300}}>
<label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>State</label>
<input value={Vdata.SSIstate} style={{fontSize:12,padding:"3px 10px 5px 10px",width:169}}>
</input>
</div>


<div style={{padding:5, textAlign:"end",width:300}}>
<label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>District</label>
<input value={Vdata.SSDistrict}  style={{fontSize:12,padding:"3px 10px 5px 10px",width:169}}>
</input>
</div>
 */}


<div style={{width:"100%",padding:"5px 5px 5px 20px",margin:"10px 0px 10px 0px",backgroundColor:"#636694",fontSize:10,color:"white"}}>TAX Details</div>

<DivInput title='PAN' place='PAN number' value={Vdata.PAN}  type='text'/>
<DivInput title='GST NO.' place='GST number' value={Vdata.GST}  type='text'/>



{/* <div style={{width:"100%",padding:"5px 5px 5px 20px",margin:"10px 0px 10px 0px",backgroundColor:"#636694",fontSize:10,color:"white"}}>BANK Details</div>

<DivInput title='A/C No' place='Account No' value={Vdata.AcNumber}  type='number'/>
<DivInput title='IFSC code' place='ifsc code' value={Vdata.Ifsc}  type='text'/>
<DivInput title='Bank' place='Enter Bank Name' value={Vdata.Bank}  type='text'/>
<DivInput title='Branch' place='Enter Branch' value={Vdata.Branch}  type='text'/> */}

<table style={{width:"100%",marginTop:20,marginBottom:50}}>
                              <tbody>
                          <tr style={{backgroundColor:"#322869",color:'white',fontSize:12}}>
                              <th style={{padding:5}}>S.no.</th>
                              <th>Date</th>
                              <th>Particulars</th>
                              <th>Cheque/Ref.</th>
                              <th style={{width:80,textAlign:"end",paddingRight:20}}>Dr</th>
                              <th style={{width:80,textAlign:"end",paddingRight:20}}>Cr</th>
                              <th style={{width:100,textAlign:"end",paddingRight:20}}>Balance</th>
                          </tr>
                          { data.map((item , index)=>(
              <tr key={index} style={{fontSize:12,backgroundColor:color(index)}}>
                    <td style={{padding:5}}>{index + 1}</td>
                    <td>{item.date}</td>
                    <td>{item.PaymentMode}</td>
                    <td>{item.ChequeBankNo}</td>
                    <td style={{width:80,textAlign:"end",paddingRight:20}}>{item.Cr}</td>
                    <td style={{width:80,textAlign:"end",paddingRight:20}}>{item.Dr}</td>
                    <td style={{width:100,textAlign:"end",paddingRight:20}}>{totalbalance(index)>0?totalbalance(index)+' '+'Cr':(-totalbalance(index))+' '+'Dr'}</td>
                </tr>
                    ))}
                    {/* <tr style={{fontSize:12,width:"100%",backgroundColor:"White"}}>
                  <td></td>
                  <td></td>
                  <td>Total-</td>
                  <td></td>
                  <td style={{width:80,textAlign:"end",paddingRight:20}}>{total.Dr}</td>
                  <td style={{width:80,textAlign:"end",paddingRight:20}}>{total.Cr}</td>
              </tr> */}
                         
                          </tbody>
                      </table>  



</div>
</div>
  )
}

export default VendorInfo
function DivInput(props){

    return (
        <div style={{padding:5, textAlign:"end",width:300}}>
        <label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>{props.title}</label>
        <input value={props.value} placeholder={props.place} style={{fontSize:12,padding:"3px 10px 5px 10px"}} type={props.type}/>
        </div>
  )}