import React, { useEffect, useState,useRef } from 'react';
import axios from "axios";
import { Page, Text, View, Document, StyleSheet,PDFViewer} from '@react-pdf/renderer';
import { MyNav } from './MyNav';



const ViewLedger = () => {


    const [fromdate, setfromdate] = useState("");
    const [todate, settodate] = useState("");
const [mode, setmode] = useState("Cash");
const [GroupType, setGroupType] = useState("Income");
const [GroupName, setGroupName] = useState("");
const [LedgerName, setLedgerName] = useState("");
const [printdata, setprintdata] = useState(false);



    const color =(index)=>{
        if ((index+1)% 2===0) {
          return "white"
        } else {
          return "#D5FFFC"
        }
      }
      const styles = StyleSheet.create({
        page: {
          padding:"30px 2px 30px 2px",
        },
        section: {
         
          padding: 10,
        
        }
      });

      const [data, setData] = useState([]);
      const [ledger, setledger] = useState([]);
    
      const getApiData = async()=>{
        const api = 'https://api.mountreachpos.duniyape.in/get-ledgerbooks-by-name/'+LedgerName+"/"+'I89nD59Ra69j0ee512TsW143eEt03u'
        try{
          const res = await axios.get(api);
          if(res){
           let data=Object.values(res.data);
           const sortedItems = data.sort((a, b) => new Date(a.date) - new Date(b.date));
          //  setledger(sortedItems)
           let opening=await opningbalhead(sortedItems)
           console.log(opening)

           let f=sortedItems.filter(item=>item.date>=fromdate&&item.date<=todate&&item.BranchCode===branch)
           console.log(f)
           let arr=[];
           for (let i = 0; i < f.length; i++) {
            arr=[...arr,{...f[i],Totalbal:(total(i,f,opening))}]
           }
           console.log(arr)
           setData(arr)
          }
          
        }catch(error){
          console.log(error);
        }
      }

      const total=(len,data,openbal)=>{
        let dr = 0;
        let cr = 0;
        for (let i = 0; i <=len; i++) {
          dr = dr+parseFloat(data[i].Dr)
          cr = cr+parseFloat(data[i].Cr)
        }
        let sum=cr-dr+openbal
        let head=sum>0?"Cr":"Dr"
        let value=sum>0?sum:sum*(-1)
        return value+" "+head
      }


        const [ldata, setlData] = useState([]);
        const getData = async()=>{
          const api = 'https://api.mountreachpos.duniyape.in'+'/get-ledger/'+'I89nD59Ra69j0ee512TsW143eEt03u'
          try{
            const res = await axios.get(api);
            if(res){
              setlData(Object.values(res.data));
              console.log(Object.values(res.data));
            }
            
          }catch(error){
            console.log(error);
          }
        }
        useEffect(() => {   
            getData();
          }, [])

          const [gdata, setgData] = useState([]);

          const getgData = async()=>{
            const api = 'https://api.mountreachpos.duniyape.in'+'/get-group/'+'I89nD59Ra69j0ee512TsW143eEt03u'
            try{
              const res = await axios.get(api);
              if(res){
                setgData(Object.values(res.data));
                console.log(Object.values(res.data));
              }
              
            }catch(error){
              console.log(error);
            }
          }
        
          useEffect(() => {
              getgData();
              getBData()
            }, [])


            const [openingdata, setopeningdata] = useState([]);
            const [openingdiv, setopeningdiv] = useState(false);
            const [openbal, setopenbal] = useState(0);
            useEffect(() => {
              if(LedgerName!==""){
                const op = data.filter((item)=> item.SearchLedger===LedgerName&&item.BranchCode===branch);
                const filteredItems = op.filter((item, index) => index < 1);
                console.log(op)
                setopeningdata(filteredItems);
                setopeningdiv(true);

                
              }
            }, [data,LedgerName])
            

            const [HeadType, setHeadType] = useState("");
            useEffect(() => {
              if (GroupType==="Income"||GroupType==="Liabilty") {
                setHeadType("Cr");
              } else {
                setHeadType("Dr");
              }
            }, [GroupType])

    const [Dbranch, setDbranch] = useState([]);
    const [branch, setbranch] = useState("001");
              
    const getBData = async()=>{
      const api = 'https://api.mountreachpos.duniyape.in'+'/fatch-branch/'+'I89nD59Ra69j0ee512TsW143eEt03u'
      try{
        const res = await axios.get(api);
        if(res){
          setDbranch(Object.values(res.data));
          console.log(Object.values(res.data));
        }
        
      }catch(error){
        console.log(error);
      }
      }


const opningbalhead=async(ledger)=>{
let f=ledger.filter(item=>item.BranchCode===branch&&item.date<fromdate)
let dr = 0;
let cr = 0;
console.log(f)
for (let i = 0; i <f.length; i++) {
  dr = dr+parseFloat(f[i].Dr)
  cr = cr+parseFloat(f[i].Cr)
}
 setopenbal(cr-dr)
 return cr-dr
}

const get=()=>{
  let f=ldata.filter(item=>item.Pushkey===LedgerName)[0].LedgerName
  return f
}


  return (

    <>
    {printdata?<>
      <div style={{
        position:'fixed',
        top:"12%",
        right:'10%',
        left:'10%',
        bottom:'10%',
        backgroundColor:'white',
        boxShadow: "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px",
        borderRadius:8
      }}>
  
  <div style={{width:'100%',textAlign:'right',padding:'0 8px 0 0',color:'black'}}>
  <i onClick={()=>setprintdata(false)} class="fa-solid fa-xmark"></i></div>
  
  <PDFViewer style={{width:"100%",height:"92%"}}>
              <Document style={{fontSize:8}} >
              <Page size="A4" style={styles.page}>
                <View>
                  <Text style={{textAlign:"center",fontSize:14,marginBottom:10}}>{get(LedgerName)} Ledger</Text>
                </View>
  
              <View style={{flexDirection:"row",justifyContent:"space-evenly",margin:"0px 10px 10px 10px",padding:"5px 10px", backgroundColor:"#F0F0F0"}}>
              <Text style={{width:"3vw"}}>Sno.</Text>
              <Text style={{width:"5vw"}}>Date</Text>
              <Text style={{width:"20vw"}}>Particular</Text>
              <Text style={{width:"10vw"}}>Bank/Cheque Ref.</Text>
              <Text style={{width:"10vw", textAlign:"right"}}>Dr</Text>
              <Text style={{width:"10vw", textAlign:"right"}}>Cr</Text>
              <Text style={{width:"15vw", textAlign:"right"}}>Balance</Text>
           
              </View>
              
              <View style={{flexDirection:"row-reverse",justifyContent:"end",margin:"0px 10px 10px 10px",padding:"0px 10px"}}>
                    <Text style={{width:"18vw",textAlign:"right"}}>{openbal>0?openbal:openbal*(-1)} {openbal>0?"Cr":"Dr"}</Text>
                </View>
                  
              {data.filter((item)=> item.SearchLedger===LedgerName&&item.BranchCode===branch).map((item,index)=>(
                <View style={{flexDirection:"row",justifyContent:"space-evenly" ,padding:"3px 0px"}}>
                <Text style={{width:"3vw"}}>{index+1}</Text>
                <Text style={{width:"5vw"}}>{item.date}</Text>
                <Text style={{width:"20vw"}}>{item.PaymentMode}-{item.AccountNumber}</Text>
                <Text style={{width:"10vw"}}>{item.BankChaqueRef}</Text>
                <Text style={{width:"10vw",textAlign:"right"}}>{parseFloat(item.Dr).toFixed(2)}</Text>
                <Text style={{width:"10vw",textAlign:"right"}}>{parseFloat(item.Cr).toFixed(2)}</Text>
                <Text style={{width:"15vw",textAlign:"right"}}>{parseFloat(item.Totalbal).toFixed(2)}</Text>
            
                </View>
              ))}
            </Page>
              </Document>
      
            </PDFViewer>
  
      </div>
      </>:<></>
  }

<MyNav/>

    <div style={{
        width:"100%"
    }}>
        
        <div style={{
        margin:"20px 2% 0px 2%",
        display:'flex',
        flexDirection:'row',
        flexFlow:'wrap',
        // justifyContent:"space-around"
    }}>
<div style={{width:"100%",padding:"8px 5px 8px 20px",textTransform:"Uppercase",margin:"10px 0px 10px 0px",backgroundColor:"#636694",fontSize:10,fontWeight:"bold", color:"white",display:'flex',justifyContent: 'space-between'}}>View Ledger<div style={{display:'flex'}}><p style={{margin:0,paddingRight:20}} onClick={()=>setprintdata(true)}><i style={{marginRight:5}} className="fa-solid fa-download"></i>Download PDF</p></div></div>
{/* <div style={{padding:5, textAlign:"end",width:300}}>
            <label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>Branch Name</label>
            <select value={branch} onChange={(e)=>setbranch(e.target.value)} style={{fontSize:12,padding:"3px 10px 5px 10px",width:169}}>
            <option value="Admin">--Select--</option>
                {props.EmpBranch==='Admin'?
                  Dbranch.map((item , index)=>(
                    <option key={index} value={item.BranchCode}>{item.BranchName}</option>
                  )):<option value={props.EmpBranch}>{props.EmpBr}</option>
                }
        </select>
        </div> */}


<div style={{padding:5, textAlign:"end",width:300}}>
                    <label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>Group Type</label>
                    <select value={GroupType} onChange={(e)=>setGroupType(e.target.value)} style={{fontSize:12,padding:"3px 10px 5px 10px",width:169}}>
                      <option value="Income">Income</option>
                      <option value="Expense">Expense</option>
                      <option value="Asset">Asset</option>
                      <option value="Liabilty">Liabilty</option>
                    </select>
                    </div>

                    <div style={{padding:5, textAlign:"end",width:300}}>
                  <label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>Group Name</label>
                  <select value={GroupName} onChange={(e)=>setGroupName(e.target.value)} style={{fontSize:12,padding:"3px 10px 5px 10px",width:169}}>
                  <option value="">-Select-</option>
                    {
                  gdata.filter((item)=> item.GroupType===GroupType).map((item , index)=>(
                    <option key={index} value={item.GroupName}>{item.GroupName}</option>
                  ))
                }
                  </select>
                  </div>

                    <div style={{padding:5, textAlign:"end",width:300}}>
                  <label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>Ledger Name</label>
                  <select value={LedgerName} onChange={(e)=>setLedgerName(e.target.value)} style={{fontSize:12,padding:"3px 10px 5px 10px",width:169}}>
                  <option value="">-Select-</option>
                    {
                  ldata.filter((item)=> item.GroupName===GroupName).map((item , index)=>(
                    <option key={index} value={item.Pushkey}>{item.LedgerName}</option>
                  ))
                }
                  </select>
                  </div>


                  <div style={{padding:5, textAlign:"end",width:300}}>
                  <label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>From Date</label>
                  <input  value={fromdate} onChange={(e)=>setfromdate(e.target.value)} placeholder='' style={{fontSize:12,padding:"3px 10px 5px 10px",width:169}} type='date'/>
                  </div>
        
                  <div  style={{padding:5, textAlign:"end",width:300}}>
                  <label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>To Date</label>
                  <input value={todate} onChange={(e)=>settodate(e.target.value)} placeholder='' style={{fontSize:12,padding:"3px 10px 5px 10px",width:169}} type='date'/>
                  </div> 

                    <div style={{width:"100%",padding:"5px 5px 5px 20px",margin:"10px 0px 10px 0px",fontSize:12,textAlign:"end"}}>
                      <button onClick={getApiData} style={{padding:"3px 30px 3px 30px",color:"white",backgroundColor:"#322869"}}>Search</button>
                  </div> 


                  <table style={{width:"100%",marginTop:20}}>
                              <tbody>
                          <tr style={{backgroundColor:"#322869",color:'white',fontSize:12}}>
                              <th style={{padding:5}}>S.no.</th>
                              <th>Date</th>
                              <th>Particular</th>
                              <th>Cheque/Ref.</th>
                              <th style={{width:80,textAlign:"end"}}>Dr</th>
                              <th style={{width:80,textAlign:"end"}}>Cr</th>
                              <th style={{width:100,textAlign:"end",paddingRight:20}}>Balance</th>
                          </tr>
{
  openingdiv?<>
                            {/* { openingdata.map((item , index)=>( */}
              <tr style={{fontSize:12,width:"100%",backgroundColor:"White"}}>
                    <td></td>
                    <td></td>
                    <td>Opening Balance -</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td style={{paddingRight:20,textAlign:"end",margin:0,padding:'3px 0px 3px 1px'}}>{openbal>0?openbal:openbal*(-1)} {openbal>0?"Cr":"Dr"}</td>
                </tr>
                    
  </>:<></>
}

                          
                          { data.map((item , index)=>(
              <tr key={index} style={{fontSize:12,backgroundColor:color(index)}}>
                    <td style={{padding:5}}>{index + 1}</td>
                    <td>{item.date}</td>
                    <td>{item.PaymentMode}-{item.AccountNumber}</td>
                    <td>{item.BankChaqueRef}</td>
                    <td style={{width:80,textAlign:"end"}}>{parseFloat(item.Dr).toFixed(2)}</td>
                    <td style={{width:80,textAlign:"end"}}>{parseFloat(item.Cr).toFixed(2)}</td>
                    <td style={{width:80,textAlign:"end"}}>{parseFloat(item.Totalbal).toFixed(2)}</td>
                </tr>
                    ))}
                         
                          </tbody>
                      </table>



</div>
</div>
</>
  )
}

export default ViewLedger