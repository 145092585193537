import React, { useEffect, useState } from 'react'
import { getDatabase, ref, update,push,child,get, set} from "firebase/database";
import {app} from "./api.js";
import moment from 'moment';
import axios from 'axios';
import CashNumbering from './CashNumbering.js';
import ReceiptVoucher from './ReceiptVoucher.js'
import { MyNav } from './MyNav.js';


const db = getDatabase(app);
const dbRef = ref(getDatabase(app));

const Voucher = (props) => {

    const color =(index)=>{
        if ((index+1)% 2===0) {
          return "white"
        } else {
          return "#D5FFFC"
        }
      }

      const [Data, setData] = useState([])
      const [lData, setlData] = useState([])


      const getdata=()=>{
  
  
        get(child(dbRef, `MyLedger/`)).then((snapshot) => {
          if (snapshot.exists()) {
            const num = Object.values(snapshot.val());
            setData(num);
            console.log(num)
          }
        }).catch((error) => {
          console.error(error);
        });

        get(child(dbRef, `LedgerName/`)).then((snapshot) => {
            if (snapshot.exists()) {
              const num = Object.values(snapshot.val());
              setlData(num);
              console.log(num)
            }
          }).catch((error) => {
            console.error(error);
          });

        }

        useEffect(() => {
            getdata()
        }, [])
        

        const [Remark, setRemark] = useState('')
        const [grouptype, setgrouptype] = useState("Income")
        const [LedgerName, setLedgerName] = useState('')
        const [myDate, setmyDate] = useState('')
        const [VoucherType, setVoucherType] = useState('Payment')
        const [Amount, setAmount] = useState('')


        const submit=()=>{
            const newPostKey = push(child(ref(db), 'MyLedger/')).key;


        const data = {
            date: myDate,
            AccountNumber: '***',
            Remark:Remark,
            Cr:VoucherType==='Receipt'?Amount:0,
            Dr:VoucherType==='Receipt'?0:Amount,
            grouptype,VoucherType,LedgerName
        };

        update(ref(db, 'MyLedger/'+ newPostKey+'/'), data).then(()=>console.log('done'));
        getdata()
        }


        const date = new Date();
        const formattedDate = moment(date).format('yyyy-MM-DD');
        const [Ddate, setDdate] = useState(formattedDate);
      const [year, setyear] = useState("");
      
      
        const [Ledgername, setLedgername] = useState("");
        const [Balance, setBalance] = useState("");
        const [loading, setloading] = useState(false);
        const [remark, setremark] = useState("")
        const [GroupType, setGroupType] = useState("Expense")
      
      
      
        const [alertcolor, setalertcolor] = useState('green');
        const [alert, setalert] = useState(false);
        const [alertmsg, setalertmsg] = useState(null);
        const [VID, setVID] = useState("");
        const [Vname, setVname] = useState("");
        const [VBalance, setVBalance] = useState("");
        
        // const [paymentmode, setpaymenmode] = useState("Cash");
       const [Mode, setMode] = useState("Cash");
       const [ChequeNo, setChequeNo] = useState("");
       const [debitto, setdebitto] = useState("");
       const [Bankname, setBankname] = useState("");
       const [BankRefNo, setBankRefNo] = useState("");
       const [DateOfPayment, setDateOfPayment] = useState("");
       const [branch, setbranch] = useState('001');
       
       const [DebitAssetstype, setDebitAssetstype] = useState("Assets-Cash");
       const myalart =(color,msg,btn)=>{
        setalertcolor(color);
        setalertmsg(msg);
        setalert(btn);
      }
  
      useEffect(() => {
        const timer = setTimeout(() => {
          alert && setalert(false);
        }, 3000);
        return () => clearTimeout(timer);
      }, [alert]);
      const [vdata, setvdata] = useState([])
        const [searchby, setsearchby] = useState('Fname')
         const getvdata=async()=>{
            try {
                await axios.get('https://api.mountreachpos.duniyape.in/create-vendor/I89nD59Ra69j0ee512TsW143eEt03u')
                .then((res)=>{
                    let data=Object.values(res.data)
                    setvdata(data)
                  //   let f=data.filter(item=>item.Code===VID)
                  //  if (f.length>0)
                  //  {
                  //   console.log(f[0].FirmName+" "+f[0].FirmType)
                  //   setVname(f[0].FirmName+" "+f[0].FirmType)
                  //   setVBalance(f[0].Balance)
                  //  }
                  //  else{
                  //   myalart('#DD4444','Vendor Not Found',true)
                  //   setVname("")
                  //   setVBalance('')
                  // }
                  //   console.log(f)
                })
        
            } catch (error) {
                console.log(error)
            }
        }
        useEffect(() => {
          getvdata()
        }, [])

          const [ldata, setldata] = useState([]);

          const getData = async()=>{
            const api = 'https://api.mountreachpos.duniyape.in'+'/get-ledger/I89nD59Ra69j0ee512TsW143eEt03u'
            try{
              const res = await axios.get(api);
              if(res){
                setldata(Object.values(res.data));
                console.log(Object.values(res.data));
              }
              
            }catch(error){
              console.log(error);
            }
          }
        
          useEffect(() => {
              getData();
            }, [])

         

            const check=()=>{
              if(branch===""||Ledgername===""||Balance===""){
                myalart('#DD4444','Please fill Vouhers Details',true);
              }else{
                Add()
              }
            }


            const [items, setItems] = useState([]);

            // Function to add an item to the array
            const Add = () => {
             const newlname=ldata.filter(item=>item.Pushkey===Ledgername)[0].LedgerName
              
              // Use spread operator to create a new array with the added item
              if(Vname===''){
                setItems(prevItems => [...prevItems, {
                  date:Ddate,
                 BranchCode:branch,
                 ledgerName:Ledgername,
                 lname:newlname,
                 Balance:Balance,
                 Gname:GroupType,
                 remark

          }]);
              }else{
                setItems(prevItems => [...prevItems, {
                  date:Ddate,
                  VID,Vname,
                 BranchCode:branch,
                 ledgerName:Ledgername,
                 lname:newlname,
                 Balance:Balance,
                 Gname:GroupType,
                 remark

          }]);
              }
            
              // setbranch("")
              // setLedgername("")
              setBalance("")
              // setGroupType("")
              setremark("")

            };

            const removeItem = (index) => {
              if (items.length > 0) {
                const indexToRemove = index;
                const filteredData = items.filter((item, index) => index !== indexToRemove);
                setItems(filteredData);
              }
            };


            const [Tatalamount, setTatalamount] = useState("");
            useEffect(() => {
              let total = 0;
              for (let i = 0; i < items.length; i++){
                  const bal = items[i].Balance
                  total += parseFloat(bal)
              }
              setTatalamount(total);
            }, [items])


            const [id, setid] = useState(null);
            useEffect(() => {
              if (id===null) {
                
              } else {
                setAmount(id.amount);
              }
            }, [id])

            const Reset=()=>{
            setMode("");
            setBankname("");
            setChequeNo("");
            setDateOfPayment("");
            setdebitto("");
            setRemark("");
            setBankRefNo("");
            setItems([])
            }
            const notescount=async(key)=>{
              const API ='https://api.mountreachpos.duniyape.in/note-count-system/I89nD59Ra69j0ee512TsW143eEt03u'
              try {
                const res = await axios.post(API,{...id,BranchCode:'001',empID:'*'})
              const data=  await res.data;
              console.log(data)
              if (data.error) {
                myalart('#DD4444','Deposit Failed',true);
                setloading(false);
              } else {
                myalart('green',`Deposit Successfully ${key?'Txn -'+key:" "}`,true);
                Reset()
                setloading(false);

              }
              } catch (error) {
                console.log("notes counting api error",error)
                setloading(false);
              }
              
            }
            const create=async()=>{
              setloading(true);
             let pdata= [...items, {
                date:Ddate,
               BranchCode:'001',
               DebitTo:debitto,
               PaymentMode:Mode,
               ChequeNo,
               BankRef:BankRefNo,
               BankName:Bankname,
               Remarks:Remark,
               Balance: Amount,EmpID:'*'
         }]
         console.log(pdata)
        let api
         VoucherType==="Payment"?
          api = 'https://api.mountreachpos.duniyape.in/make-payment-voucher/'+"I89nD59Ra69j0ee512TsW143eEt03u"
         :api='https://api.mountreachpos.duniyape.in/make-receipt-voucher/'+"I89nD59Ra69j0ee512TsW143eEt03u"
          try{
           await axios.post(api,pdata).then((response)=>{
            console.log(response.data)
            // if (Mode==="Cash") {
              // notescount(response.data.VsKey)
            //  } else {
              setloading(false);
              myalart('green',`Deposit Successfully ${response.data.VsKey?'Txn -'+response.data.VsKey:" "}`,true);
              Reset()
            //  }
          })
          }catch(error){
            console.log(error);
            setloading(false);
            myalart('#DD4444','Error',true);
          }
        }


            useEffect(() => {
              if (Mode==="Cheque") {
                setDebitAssetstype("Assets-Bank");
                setdebitto("");
        
              } else if(Mode==="Bank Transfer"){
                setDebitAssetstype("Assets-Bank");
                setdebitto("");
              } else if(Mode==="Cash"){
                setDebitAssetstype("Assets-Cash");
                setdebitto("A11");
              } else{
                setdebitto("");
              }
            }, [Mode])

            const checkMOP=()=>{

              if (Mode==="Cheque") {
                if(debitto===""||ChequeNo===""||Bankname===""||DateOfPayment===""){
                myalart('#DD4444','Please fill Cheque Details',true);
                }else{ 
                     if (parseFloat(Tatalamount)!==parseFloat(Amount)) {
                      myalart('#DD4444','Deposit Amount is not Equal to Voucher Amount ',true);
                    }else{
                      create()
                    }
                }
              } else if(Mode==="Bank Transfer") {
                if(debitto===""||BankRefNo===""||DateOfPayment===""){
                  myalart('#DD4444','Please fill Bank Transfer Details',true);
                }else{ 
                     if (parseFloat(Tatalamount)!==parseFloat(Amount)) {
                      myalart('#DD4444','Deposit Amount is not Equal to Voucher Amount ',true);
                    }else{
                      create()
                    }
                }
              }else if(Mode==="Cash"){
                   if (parseFloat(Tatalamount)!==parseFloat(Amount)) {
                    myalart('#DD4444','Deposit Amount is not Equal to Voucher Amount ',true);
                  }else{
                    create()
                  }

              }
            }
           
            useEffect(() => {
             setGroupType(VoucherType==="Receipt"?"Asset":"Liabilty")
             setLedgername(VoucherType==="Receipt"?"A63":"A64")
            }, [VoucherType])
            
            const [input, setInput] = useState('');
            const [suggestions, setSuggestions] = useState([]);
            const [showSuggestions, setShowSuggestions] = useState(false);
        
         
            // Handle input change and filter suggestions
            const handleChange = (e) => {
               const value =e.target.value;
               setInput(value);
               setVname('')
              //  console.log(vdata)
               if (value) {
                  const filteredSuggestions = vdata.filter(suggestion =>
                    searchby==='Other'?suggestion.Other.startsWith(value):suggestion[searchby].toLowerCase().startsWith(value.toLowerCase())
                );
                  setSuggestions(filteredSuggestions);
                  setShowSuggestions(true);
               } else {
                  setSuggestions([]);
                  setShowSuggestions(false);
               }
            };
         
            // Handle selecting a suggestion
            const handleSuggestionClick = (suggestion) => {
               setInput(suggestion[searchby]);
               setVname(suggestion.Fname)
               setVID(suggestion.Code)
               setSuggestions([]);
               setShowSuggestions(false);
               console.log(suggestion)
            };
         

  return (
<>

    <MyNav/>

    <div style={{
        margin:"20px 2% 0px 2%",
        display:'flex',
        flexDirection:'row',
        flexFlow:'wrap',
        // justifyContent:"space-around"
    }}>


<div style={{padding:5, textAlign:"end",width:300}}>
                    <label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>Voucher type</label>
                    <select value={VoucherType} onChange={(e)=>setVoucherType(e.target.value)} style={{fontSize:12,padding:"3px 10px 5px 10px",width:169}}>
                      <option value="Receipt">Receipt</option>
                      <option value="Payment">Payment</option>
                    </select>
                    </div>


{/* {VoucherType==="Receipt"?<ReceiptVoucher ledger={ldata}/>:<div style={{
      width:"100%"
  }}> */}
<div style={{
      width:"100%"
  }}>

    
{alert?<><div style={{
width:"100%",
backgroundColor:alertcolor,
color:"white",
textAlign:'center',
padding:8,
}}>
<h6 style={{fontSize:12}}>{alertmsg}</h6>
</div></>:<></>}
      
      <div style={{
      margin:"20px 2% 0px 2%",
      display:'flex',
      flexDirection:'row',
      flexFlow:'wrap',
      // justifyContent:"space-around"
  }}>

<div style={{width:"100%",padding:"8px 5px 8px 20px",textTransform:"Uppercase",margin:"10px 0px 10px 0px",backgroundColor:"#636694",fontSize:10,fontWeight:"bold", color:"white"}}>Voucher</div>
      
<div style={{padding:5, textAlign:"end",width:300}}>
<label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>Date</label>
<input value={Ddate} onChange={(e)=>setDdate(e.target.value)} style={{fontSize:12,padding:"3px 10px 5px 10px",width:169}} type='date'/>
</div>


        {/* <div style={{padding:5, textAlign:"end",width:300}}>
            <label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>Vendor ID</label>
            <input value={VID} onChange={e=>setVID(e.target.value)}  placeholder={'Enter Vendor ID'}style={{fontSize:12,padding:"3px 10px 5px 10px",width:169}} type='text'/>
        </div> */}
        <div style={{padding:5, textAlign:"end",width:300}}>
                    <label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>Search By</label>
                    <select value={searchby} onChange={(e)=>setsearchby(e.target.value)} style={{fontSize:12,padding:"3px 10px 5px 10px",width:169}}>
                      <option value="Fname">Name</option>
                      <option value="Code">ID</option>
                      <option value="Other">Mobile No</option>
                      <option value="GST">GST No</option>
                    </select>
                    </div>
        <div style={{padding:5,position: 'relative', textAlign:"end",width:300}}>
            <label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>Search Vendor</label>
            <input value={input} onChange={handleChange} placeholder="Type to search..." style={{fontSize:12,padding:"3px 10px 5px 10px",width:169}} type='text'/>
            {showSuggestions && suggestions.length > 0 && (
            <ul style={{
               position: 'absolute',
               fontSize:12,
               top:'85%',
              //  left: 0,
               right: 0,
               listStyle: 'none',
               padding: 0,
               margin: '0 5px',
               border: '1px solid #ccc',
               borderRadius: '4px',
               backgroundColor: '#fff',
               zIndex: 1,
               width:169
            }}>
               {suggestions.map((suggestion, index) => (
                  <li
                     key={index}
                     onClick={() => handleSuggestionClick(suggestion)}
                     style={{
                        padding: '8px',
                        cursor: 'pointer'
                     }}
                     onMouseEnter={(e) => e.target.style.backgroundColor = '#f0f0f0'}
                     onMouseLeave={(e) => e.target.style.backgroundColor = '#fff'}
                  >
                     {suggestion[searchby]}
                  </li>
               ))}
            </ul>
         )}
        </div>

            <div style={{padding:5, textAlign:"end",width:300}}>
            <label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>Firm Name</label>
            <input value={Vname}  placeholder='Firm'style={{fontSize:12,padding:"3px 10px 5px 10px",width:169}} type='text'/>
            </div>
            {/* <div style={{padding:5, textAlign:"end",width:300}}>
            <label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>Balance</label>
            <input value={VBalance}  placeholder='Firm'style={{fontSize:12,padding:"3px 10px 5px 10px",width:169}} type='text'/>
        </div> */}
       


<div style={{padding:5, textAlign:"end",width:300}}>
            <label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>Group Type</label>
            <select value={GroupType} onChange={(e)=>setGroupType(e.target.value)} style={{fontSize:12,padding:"3px 10px 5px 10px",width:169}}>
            <option value="">-select-</option>
              {VoucherType==="Receipt"?<><option value="Expense">Expense</option>
              <option value="Asset">Asset</option></>:  
              <><option value="Income">Income</option>
              <option value="Liabilty">Liabilty</option></>}
            </select>
            </div>


<div style={{padding:5, textAlign:"end",width:300}}>
    <label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>Select Ledger</label>
    <select value={Ledgername} onChange={(e)=>setLedgername(e.target.value)} style={{fontSize:12,padding:"3px 10px 5px 10px",width:169}}>
        <option value="">-Select-</option>
        {
          ldata.filter((item)=>item.GroupType===GroupType).map((item , index)=>(
            <option key={index} value={item.Pushkey}>{item.LedgerName}</option>
          ))
        }      
</select>
</div>



<div style={{padding:5, textAlign:"end",width:300}}>
<label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>Amount</label>
<input value={Balance} onChange={(e)=>setBalance(e.target.value)} placeholder={'Enter Amount'}style={{fontSize:12,padding:"3px 10px 5px 10px",width:169}} type='text'/>
</div>
<div style={{padding:5, textAlign:"end",width:300}}>
<label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>Remarks</label>
<input value={remark} onChange={(e)=>setremark(e.target.value)} placeholder={'Enter Remarks'}style={{fontSize:12,padding:"3px 10px 5px 10px",width:169}} type='text'/>
</div>
<div style={{width:"100%", textAlign:"end"}}>
<button onClick={check} style={{borderRadius:2,padding:"5px 30px 5px 30px",margin:"20px 10px 0px 0px", fontSize:12,backgroundColor:"#322869"}} type="button" className="btn btn-success">ADD</button>
</div>



   <table style={{width:"100%",marginTop:20}}>
      <tr style={{backgroundColor:"#322869",color:'white',fontSize:12}}>
          <th style={{padding:5}}>S.no.</th>
          <th>Group Type</th>
          <th>Ledger Name</th>
          <th>Amount</th>
          <th> </th>
      </tr>

      {items.filter(item=>item.BranchCode===branch).map((item , index)=>(
          <tr key={index} style={{fontSize:12,backgroundColor:color(index)}}>
            <td style={{padding:5}}>{index + 1}</td>
            <td>{item.Gname}</td>
            <td>{item.lname}</td>
            <td>{item.Balance}</td>
            <td><i onClick={()=>removeItem(index)} className="fa-solid fa-xmark"></i></td>
          </tr>
        ))
        
      }
  
  {
    items.length!==0&&  <tr style={{fontSize:12,backgroundColor:'#D9E1FF',fontWeight:'bold',marginBottom:"50px"}}>
    <td style={{padding:5}}>{'>'}</td>
    <td style={{padding:5}}>{'Total'}</td>
    <td></td>
    <td>{Tatalamount}</td>
    <td></td>
  </tr>
  }
     

  </table>


   

  <div style={{width:"100%",padding:"5px 5px 5px 20px",margin:"30px 0px 10px 0px",backgroundColor:"#636694",fontSize:10,color:"white"}}>Payment</div>



  <div style={{padding:5, textAlign:"end",width:300}}>
      <label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>Payment mode</label>
      <select value={Mode} onChange={(e)=>setMode(e.target.value)} style={{fontSize:12,padding:"3px 10px 5px 10px",width:169}}>
          <option value="">-Select-</option>     
          <option value="Cash">Cash</option>     
          <option value="Cheque">Cheque</option>     
          <option value="Bank Transfer">Bank Transfer</option>        
  </select>
  </div>

  <div style={{padding:5, textAlign:"end",width:300}}>
  <label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>Credit To</label>
  <select value={debitto} onChange={(e)=>setdebitto(e.target.value)} style={{fontSize:12,padding:"3px 10px 5px 10px",width:169}}>
          <option value="">-Select-</option>
          {
      ldata.filter((item)=> item.GroupName===DebitAssetstype).map((item , index)=>(
        <option key={index} value={item.Pushkey}>{item.LedgerName}</option>
      ))
    }
  </select>
  </div>


  {
    Mode==="Cash"?<>
    <CashNumbering x = {id} setX={setid} text={"Deposit"}/>
    </>:<></>
  }

  {
    Mode==="Cheque"?<>
    <div style={{padding:5, textAlign:"end",width:300}}>
    <label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>cheque No.</label>
    <input value={ChequeNo} onChange={(e)=>setChequeNo(e.target.value)} placeholder='Enter Plan Name'style={{fontSize:12,padding:"3px 10px 5px 10px",width:169}} type='text'/>
  </div>

  <div style={{padding:5, textAlign:"end",width:300}}>
    <label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>Bank Name</label>
    <input value={Bankname} onChange={(e)=>setBankname(e.target.value)} placeholder='Enter Plan Name'style={{fontSize:12,padding:"3px 10px 5px 10px",width:169}} type='text'/>
  </div>

  <div style={{padding:5, textAlign:"end",width:300}}>
    <label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>Date</label>
    <input value={DateOfPayment} onChange={(e)=>setDateOfPayment(e.target.value)} placeholder='Enter Plan Name' style={{fontSize:12,padding:"3px 10px 5px 10px",width:169}} type='date'/>
  </div>

  <div style={{width:"100%",padding:"5px 5px 5px 20px",margin:"10px 0px 10px 0px",backgroundColor:"#636694",fontSize:10,color:"white"}}>Update Plan</div>

  <div style={{padding:5, textAlign:"end",width:300}}>
  <label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>Deposit Amount</label>
  <input value={Amount} onChange={(e)=>setAmount(e.target.value)} placeholder='Enter Plan Name'style={{fontSize:12,padding:"3px 10px 5px 10px",width:169}} type='number'/>
  </div>
    </>:<></>
  }


  {
  Mode==="Bank Transfer"?<>
  <div style={{padding:5, textAlign:"end",width:300}}>
    <label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>Bank Ref. No.</label>
    <input value={BankRefNo} onChange={(e)=>setBankRefNo(e.target.value)} placeholder='Enter Plan Name'style={{fontSize:12,padding:"3px 10px 5px 10px",width:169}} type='text'/>
  </div>

  <div style={{padding:5, textAlign:"end",width:300}}>
    <label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>Date</label>
    <input  value={DateOfPayment} onChange={(e)=>setDateOfPayment(e.target.value)} placeholder='Enter Plan Name' style={{fontSize:12,padding:"3px 10px 5px 10px",width:169}} type='date'/>
  </div>


  <div style={{width:"100%",padding:"5px 5px 5px 20px",margin:"10px 0px 10px 0px",backgroundColor:"#636694",fontSize:10,color:"white"}}>Update Plan</div>

  <div style={{padding:5, textAlign:"end",width:300}}>
  <label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>Deposit Amount</label>
  <input value={Amount} onChange={(e)=>setAmount(e.target.value)} placeholder={'Enter Amount'}style={{fontSize:12,padding:"3px 10px 5px 10px",width:169}} type='number'/>
  </div>
  </>:<></>
  }


  <div style={{padding:5, textAlign:"end",width:300}}>
  <label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>Remarks</label>
  <input value={Remark} onChange={(e)=>setRemark(e.target.value)} placeholder={'Enter Remarks'}style={{fontSize:12,padding:"3px 10px 5px 10px",width:169}} type='text'/>
  </div>

  {
  loading? <div style={{width:"100%", height:100, textAlign:"end"}}>
  <span class="loader"></span>
  </div>
  :
  <div style={{width:"100%", height:100, textAlign:"end"}}>
  <button onClick={checkMOP}  style={{borderRadius:2,padding:"5px 30px 5px 30px",margin:"20px 10px 0px 0px", fontSize:12,backgroundColor:"#322869"}} type="button" className="btn btn-success">Create</button>
  </div>
  }


</div>
</div>
    </div>
    </>
  )
}

export default Voucher


function DivInput(props){
  
      return (
          <div style={{padding:5, textAlign:"end",width:300}}>
          <label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>{props.title}</label>
          <input value={props.value} onChange={props.getdata} placeholder={props.place} style={{fontSize:12,padding:"3px 10px 5px 10px"}} type={props.type}/>
          </div>
  )}
  